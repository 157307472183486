/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import { Flex } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import PlatformSetting from 'components/PlatformSetting'
import GeneralSmtpConfiguration from 'components/GeneralSmtpConfiguration'
import InvoiceSmtpConfiguration from 'components/InvoiceSmtpConfiguration'
import RateSmtpConfiguration from 'components/RateSmtpConfiguration'
import ImapConfiguration from 'components/ImapConfiguration'
import MccAndMncFinder from 'components/MccAndMncFinder'
import RouteTesting from 'components/RouteTesting'
import MessageTesting from 'components/MessageTesting'


/*
 * OBJECTS
 */
const Index = () => {
  // Hook assignment.
  const [forceReRender, setForceReRender] = React.useState(void 0)

  // Return component.
  return (
    <Flex flexDir='column' w='100%' gap='22px' borderRadius='22px' pb='22px'>
      <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
        <Flex flexDir='column' w={{ 'base': '100%', 'md': '70%' }} h='100%'>
          <MessageTesting />
        </Flex>
        <Flex flexDir='column' w={{ 'base': '100%', 'md': '30%' }} gap='22px'>
          <PlatformSetting key={forceReRender} />
          <MccAndMncFinder onUpdate={() => setForceReRender(String.random(9))} />
        </Flex>
      </Flex>
      <Flex w='100%' flexDir={{ 'base': 'column', 'md': 'row' }} gap='22px'>
        <Flex flexDir='column' w={{ 'base': '100%', 'md': '50%' }} h='100%' gap='22px'>
          <RouteTesting />
        </Flex>
        <Flex
          fontSize={['Clamp(13px, 1.5vw, 15px)']}
          direction={{ 'base': 'column' }}
          display='flex'
          h='100%'
          gap="22px"
          w={{ 'base': '100%', 'md': '50%' }}>
          <Flex flexDir='row' gap='22px'>
            <Flex w='50%'>
              <GeneralSmtpConfiguration key={forceReRender} />
            </Flex>
            <Flex w='50%'>
              <RateSmtpConfiguration key={forceReRender} />
            </Flex>
          </Flex>
          <Flex flexDir='row' gap='22px'>
            <Flex w='50%'>
              <ImapConfiguration key={forceReRender} />
            </Flex>
            <Flex w='50%'>
              <InvoiceSmtpConfiguration key={forceReRender} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORT
 */
export default Index
