/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    mutation CustomerInvoiceEmailMutation($customerInvoiceId: ID!) {
      CustomerInvoiceEmail(customerInvoiceId: $customerInvoiceId) {
        status,
        message
    }
  }
`
