/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
  query BilateralReadQuery($customerId: ID, $entityId: ID, $startDate: DateTime!, $endDate: DateTime!) {
    BilateralRead(customerId: $customerId, entityId: $entityId, startDate: $startDate, endDate: $endDate) {
      id,
      status,
      message,
      _totalCount,
      Customer {
        displayName,
        CustomerInvoice {
          id,
          billingStartDate,
          billingEndDate,
          amount
        },
        Payment {
          id,
          createdAt,
          amount
        }
      },
      Vendor {
        VendorInvoice {
          id,
          invoiceNumber,
          datedOn,
          amount
        },
        Payment {
          id,
          createdAt,
          amount
        }
      }
    }
  }
`
