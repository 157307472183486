/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query AutoRateImportReadQuery($vendorAccountId: ID!) {
  AutoRateImportRead(vendorAccountId: $vendorAccountId) {
    id,
    from,
    bodySearchKeyword, 
    mccHeader, 
    mncHeader, 
    rateHeader, 
    rateDecimalSeparator, 
    startingRowColumn,
    message,
    status
  }
}
`
