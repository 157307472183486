/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment.js library for date and time formatting.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: Underscore.js library for utility functions.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import { useQuery } from '@apollo/client' // Npm: Apollo client for GraphQL queries and mutations.
import {
  Button,
  Flex,
  Tab,
  Table,
  TableContainer,
  TabList,
  Tabs,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import TableSpinner from 'components/TableSpinner'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import RateUpsert from 'components/RateUpsert'
import RateDelete from 'components/RateDelete'
import RateDropDownOptions from 'components/RateDropDownOptions'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import RateReadQuery from './__query__/index.rate.read.query'


/*
 * STYLES
 */
import { buttonStyle, headerStyle, rowStyle, tabStyles } from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 8
  const _rates = Object.React.App.enums.CUSTOMER_TYPE.enums.map(i => i.key)
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [skipPage, setSkipPage] = React.useState(0)
  const [tabIndex, setTabIndex] = React.useState(0)
  const { 'isOpen': isRateCreateFormOpen, 'onOpen': onRateCreateFormOpen, 'onClose': onRateCreateFormClose } = useDisclosure()
  const { 'isOpen': isRateUpdateFormOpen, 'onOpen': onRateUpdateFormOpen, 'onClose': onRateUpdateFormClose } = useDisclosure()
  const { 'onOpen': onDeleteModalOpen, 'isOpen': isDeleteModalOpen, 'onClose': onDeleteModalClose } = useDisclosure()
  const _QueryRateRead = useQuery(RateReadQuery, {
    'variables': {
      'type': _rates[tabIndex],
      'search': searchQuery,
      'skip': skipPage * skipDifference,
      'take': skipDifference
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onSearchInputChange = React.useCallback(Debounce(e => setSearchQuery(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Check if first load is completed.
  if (!_QueryRateRead.loading && 0 < _QueryRateRead.data?.RateRead?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryRateRead.data?.RateRead?.length || (0 < _QueryRateRead.data?.RateRead?.length && (!_.every(_.pluck(_QueryRateRead.data?.RateRead, 'status'), i => _successFlags?.includes(i)) || _.some(_.pluck(_QueryRateRead.data?.RateRead, 'status'), i => 'ROUTE_PLAN_NOT_FOUND' === i)))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='rate base' height='unset !important'>
        <Flex
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          bg='linear-Gradient(73deg,#390EF6 0%, #9244F4 100%)'
          borderTopLeftRadius='20px'
          borderTopRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Tooltip label='Create new rate.' fontSize='sm'>
            <Button
              w='170px'
              onClick={onRateCreateFormOpen}
              leftIcon={<IoMdAdd />}
              style={buttonStyle}
            >
              New Rate
            </Button>
          </Tooltip>
          <DownloadToExcel
            cellsData={
              0 === _QueryRateRead?.data?.length || !_.every(_.pluck(_QueryRateRead?.data?.RateRead, 'status'), i =>
                _successFlags?.includes(i)) ? [] : _QueryRateRead?.data?.RateRead?.map((item, __index) => ({
                  'S.No.': `${__index + (skipDifference * skipPage) + 1}.`,
                  'Rate Name': item?.displayName ?? '-',
                  'Rate Type': item?.type ?? '-',
                  'CreatedAt': Moment(item.createdAt).format('YYYY-MM-DD'),
                  'UpdatedAt': Moment(item.updatedAt).format('YYYY-MM-DD'),
                  'Created By': item?.Account?.displayName ?? '-'
                }))
            }
            headersData={[
              'S.No.',
              'Rate Name',
              'Rate Type',
              'CreatedAt',
              'UpdatedAt',
              'Created By'
            ].map(i => ({ 'key': i, 'label': i }))}
          />
        </Flex>
        <Tabs
          variant='enclosed'
          onChange={r => setTabIndex(r)}
          flex={1}
          display='flex'
          flexDirection='column'
          align='center'
          w='100%'
          h='100%'
          bg='white'
          borderBottomLeftRadius='20px'
          borderBottomRightRadius='20px'
          isFitted
        >
          <TabList bg='linear-Gradient(73deg,#390EF6 0%, rgba(146, 68, 244, 1) 100%)' overflowX={_isCurrentViewMobile ? 'auto' : 'none'}>
            {_rates.map(u => (
              <>
                <Tab
                  color='white'
                  textDecoration={'line-through'}
                  _focus={{ 'boxShadow': 'none' }}
                  _selected={{ 'textDecoration': 'none' }}
                  style={{ ...tabStyles, 'border': 'none', 'borderRadius': 0 }}
                  p='1rem'
                  key={String.random(8)}
                >
                  {u}
                </Tab>
              </>
            ))}
          </TabList>
          <Flex h='100%'>
            <TableContainer
              display='flex'
              border='2px solid #3A0FF6'
              borderBottomRadius='20px'
            >
              <Table
                __css={{
                  'tableLayout': { 'base': 'auto', 'md': 'fixed' },
                  'width': { 'base': 'auto', 'md': 'full' }
                }}
                variant='simple'
                size='sm'
              >
                <Thead ref={_tableHeaderHeightRef}>
                  <Tr style={headerStyle}>
                    <Td
                      style={rowStyle}
                      px={0}
                      textAlign='center'
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    >
                      S No.
                    </Td>
                    <Td
                      style={rowStyle}
                      px={0}
                      textAlign='center'
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    >
                      Rate Name
                    </Td>
                    <Td
                      style={rowStyle}
                      px={0}
                      textAlign='center'
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    >
                      Rate Type
                    </Td>
                    <Td
                      style={rowStyle}
                      px={0}
                      textAlign='center'
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    >
                      CreatedAt
                    </Td>
                    <Td
                      style={rowStyle}
                      px={0}
                      textAlign='center'
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    >
                      UpdatedAt
                    </Td>
                    <Td
                      style={rowStyle}
                      px={0}
                      textAlign='center'
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    >
                      Created By
                    </Td>
                    <Td
                      style={rowStyle}
                      px={0}
                      textAlign='center'
                    >
                      Action
                    </Td>
                  </Tr>
                  <Tr key={String.random(8)}>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    />
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      <MemoizedInput
                        placeholder='Search'
                        className='filter searchInput'
                        bg='gray.100'
                        defaultValue={searchQuery}
                        onChange={_onSearchInputChange}
                      />
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    />
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    />
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    />
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    />
                    <Td
                      style={rowStyle}
                    />
                  </Tr>
                </Thead>
                {_QueryRateRead.loading && !_isFirstLoadCompleted.current ? (
                  <TableSpinner
                    isLoading={true}
                    chopHeightFromHundredPercentage={
                      _tableHeaderHeightRef?.current?.clientHeight
                    }
                  />
                ) : !_isFirstLoadCompleted.current && (0 === _QueryRateRead?.data?.RateRead?.length || (0 < _QueryRateRead?.data?.RateRead?.length && (!_.every(_QueryRateRead?.data?.RateRead, j =>
                  _successFlags?.includes(j.status)) || _.some(
                    _QueryRateRead?.data?.RateRead,
                    j => 'RATE_PLAN_NOT_FOUND' === j.status
                  )))) ? (
                  <TableSpinner
                    isLoading={false}
                    isEmpty={true}
                    chopHeightFromHundredPercentage={
                      _tableHeaderHeightRef?.current?.clientHeight
                    }
                  />
                ) : (
                  <Tbody>
                    {_QueryRateRead?.data?.RateRead?.map((item, index) => (
                      <Tr height='10px' key={index} textAlign='center'>
                        <Td
                          style={rowStyle}
                          textAlign='center'
                          borderRight='1px solid #D8E3FC'
                        >
                          {index + skipPage + 1}.
                        </Td>
                        <Td style={rowStyle} borderRight={'1px solid #D8E3FC'}>
                          {item?.displayName ?? '-'}
                        </Td>
                        <Td style={rowStyle} borderRight={'1px solid #D8E3FC'}>
                          {item?.type ?? '-'}
                        </Td>
                        <Td style={rowStyle} borderRight='1px solid #D8E3FC'>
                          {Moment(item.createdAt).format('YYYY-MM-DD')}
                        </Td>
                        <Td style={rowStyle} borderRight='1px solid #D8E3FC'>
                          {Moment(item.updatedAt).format('YYYY-MM-DD')}
                        </Td>
                        <Td style={rowStyle} borderRight={'1px solid #D8E3FC'}>
                          {item?.Account?.displayName ?? '-'}
                        </Td>
                        <Td style={rowStyle}>
                          <RateDropDownOptions
                            type={item.type}
                            rateId={item.id}
                            onRateDelete={onDeleteModalOpen}
                            onRateUpdate={onRateUpdateFormOpen}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                )}
              </Table>
            </TableContainer>
          </Flex>
        </Tabs>
        <Modal
          size='md'
          title='Rate Create'
          isOpen={isRateCreateFormOpen}
          onClose={onRateCreateFormClose}
        >
          <RateUpsert isCreateOnly={true} type={_rates[tabIndex]} />
        </Modal>
        <Modal
          size='md'
          title='Rate Update'
          isOpen={isRateUpdateFormOpen}
          onClose={onRateUpdateFormClose}
        >
          <RateUpsert type={_rates[tabIndex]} />
        </Modal>
        <Modal
          size='md'
          title='Rate Delete'
          isOpen={isDeleteModalOpen}
          onClose={onDeleteModalClose}>
          <RateDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryRateRead.data?.RateRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}

/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORTS
 */
export default Index
