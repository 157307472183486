/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: utility module.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import TableSpinner from 'components/TableSpinner'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import SenderID from 'components/SenderID'
import VendorAccountUpsert from 'components/VendorAccountUpsert'
import VendorAccountDelete from 'components/VendorAccountDelete'
import AutoRateImport from 'components/AutoRateImport'
import VendorAccountDropDownOptions from 'components/VendorAccountDropDownOptions'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorAccountReadQuery from './__query__/index.vendorAccount.read.query'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [vendorNameToSearch, setVendorNameToSearch] = React.useState('')
  const [vendorAccountNameToSearch, setVendorAccountNameToSearch] = React.useState('')
  const [rateNameToSearch, setRateNameToSearch] = React.useState('')
  const { 'onOpen': onVendorAccountCreateOpen, 'isOpen': isVendorAccountCreateOpen, 'onClose': onVendorAccountCreateClose } = useDisclosure()
  const { 'onOpen': onVendorAccountUpdateOpen, 'isOpen': isVendorAccountUpdateOpen, 'onClose': onVendorAccountUpdateClose } = useDisclosure()
  const { 'onOpen': onVendorAccountDeleteOpen, 'isOpen': isVendorAccountDeleteOpen, 'onClose': onVendorAccountDeleteClose } = useDisclosure()
  const { 'onOpen': onSenderIDAddOpen, 'isOpen': isSenderIDAddOpen, 'onClose': onSenderIDAddClose } = useDisclosure()
  const { 'onOpen': onAutoRateImportOpen, 'isOpen': isAutoRateImportOpen, 'onClose': onAutoRateImportClose } = useDisclosure()
  const _QueryVendorAccountRead = useQuery(VendorAccountReadQuery, { 'variables': { 'vendorId': passOn?.vendorId, vendorNameToSearch, vendorAccountNameToSearch, rateNameToSearch, 'skip': skipPage * skipDifference, 'take': skipDifference }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _onVendorNameToSearchInputChange = React.useCallback(Debounce(e => setVendorNameToSearch(e.target.value), 800), [])
  const _onVendorAccountNameToSearchInputChange = React.useCallback(Debounce(e => setVendorAccountNameToSearch(e.target.value), 800), [])
  const _onRateNameToSearchInputChange = React.useCallback(Debounce(e => setRateNameToSearch(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isFirstLoadCompleted = React.useRef(false)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data assignment.
  if (!_QueryVendorAccountRead.loading && 0 < _QueryVendorAccountRead?.data?.VendorAccountRead?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryVendorAccountRead?.data?.VendorAccountRead?.length || !_.every(_.pluck(_QueryVendorAccountRead?.data?.VendorAccountRead, 'status'), j => _successFlags.includes(j))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <><Flex className='vendorAccount base'>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap={_isCurrentViewMobile ? '12px' : '22px'}
        bg='white'
        height='100%'
        borderRadius='20px'
        p={_isCurrentViewMobile ? '12px' : '22px'}>
        <Flex pb='0px' justify='space-between' flexWrap={{ 'base': 'wrap', 'md': 'nowrap' }}>
          <Tooltip label='Create new vendor account.' fontSize='sm'>
            <Button
              onClick={onVendorAccountCreateOpen}
              leftIcon={<IoMdAdd />}
              style={buttonStyle}>
              New Account
            </Button>
          </Tooltip>
          <DownloadToExcel
            cellsData={0 === _QueryVendorAccountRead.data?.VendorAccountRead?.length || !_.every(_.pluck(_QueryVendorAccountRead.data?.VendorAccountRead, 'status'), i => _successFlags?.includes(i)) ? [] : _QueryVendorAccountRead.data?.VendorAccountRead?.map((item, __index) => ({
              'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
              'Name': item.displayName ?? '-',
              'Vendor': item?.Vendor?.displayName ?? '-',
              'Rate': item?.Rate?.displayName ?? '-',
              'Currency': item?.Rate?.currency ?? '-',
              'Status': item?.Smpp?.isActive ? 'Active' : 'In Active',
              'CreatedAt': Moment(item?.createdAt).format('YYYY-MM-DD'),
              'UpdatedAt': Moment(item?.updatedAt).format('YYYY-MM-DD')
            }))}
            headersData={[
              'S.No.',
              'Name',
              'Vendor',
              'Rate',
              'Currency',
              'Status',
              'CreatedAt',
              'UpdatedAt'
            ].map(i => ({ 'key': i, 'label': i }))}
          />
        </Flex>
        <TableContainer
          flex={1}
          display='flex'
          borderRadius='15px'
          outline='1px solid #C5CFE8'>
          <Table variant='simple' size='sm'>
            <Thead ref={_tableHeaderHeightRef}>
              <Tr style={headerStyle}>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>S.No.
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>Name
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>Vendor
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Rate
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Currency
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Status
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  CreatedAt
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  UpdatedAt</Td>
                <Td
                  style={rowStyle}>Actions</Td>
              </Tr>
              <Tr>
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                  <MemoizedInput
                    placeholder='Search'
                    className='filter searchInput'
                    bg='gray.100'
                    defaultValue={vendorAccountNameToSearch}
                    onChange={_onVendorAccountNameToSearchInputChange}
                  />
                </Td>
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                  <MemoizedInput
                    placeholder='Search'
                    className='filter searchInput'
                    bg='gray.100'
                    defaultValue={vendorNameToSearch}
                    onChange={_onVendorNameToSearchInputChange}
                  />
                </Td>
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                  <MemoizedInput
                    placeholder='Search'
                    className='filter searchInput'
                    bg='gray.100'
                    defaultValue={rateNameToSearch}
                    onChange={_onRateNameToSearchInputChange} />
                </Td>
                <Td style={rowStyle} borderRight='1px solid rgba(216  , 227, 252, 1)' />
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                <Td style={rowStyle} />
              </Tr>
            </Thead>
            {_QueryVendorAccountRead.loading && !_isFirstLoadCompleted.current ? (
              <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
            ) : !_isFirstLoadCompleted.current && (0 === _QueryVendorAccountRead.data?.VendorAccountRead.length || !_.every(_.flatten(_.pluck(_QueryVendorAccountRead.data?.VendorAccountRead, 'status')), i => _successFlags?.includes(i))) ? (
              <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
            ) : (
              <Tbody style={cellStyle}>
                {_QueryVendorAccountRead.data?.VendorAccountRead?.map(({ id, createdAt, updatedAt, displayName, Vendor, Rate, RoutePlan, Smpp }, __index) => (
                  <Tr key={String.random(9)}>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {' '}
                      {`${(__index + (0 < skipPage ? skipDifference : 0) + 1)}.`}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {' '}
                      {displayName ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {' '}
                      {Vendor?.displayName ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {' '}
                      {Rate?.displayName ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Vendor?.Billing?.currency ?? '-'}{' '}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {
                        Smpp?.isActive ? (
                          <Text
                            margin='auto'
                            width='max-content'
                            borderRadius='10px'
                            padding='5px 15px 5px 15px'
                            color='rgba(117, 81, 255, 1)'
                            bg='rgba(117, 81, 255, .1)'>
                            Active
                          </Text>
                        ) : (
                          <Text
                            margin='auto'
                            width='max-content'
                            borderRadius='10px'
                            padding='5px 15px 5px 15px'
                            color='rgba(238, 93, 80, 1)'
                            bg='rgba(255, 242, 242, 1)'>
                            In Active
                          </Text>
                        )
                      }
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {' '}
                      {Moment(createdAt).format('YYYY-MM-DD')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {' '}
                      {Moment(updatedAt).format('YYYY-MM-DD')}
                    </Td>
                    <Td
                      style={rowStyle}>
                      {' '}
                      {
                        <VendorAccountDropDownOptions
                          vendorAccountId={id}
                          smppId={Smpp?.id}
                          onVendorAccountUpdate={onVendorAccountUpdateOpen}
                          onVendorAccountDelete={onVendorAccountDeleteOpen}
                          onAutoRateImport={onAutoRateImportOpen}
                          onAddSenderID={onSenderIDAddOpen}
                        />
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            )
            }
          </Table>
        </TableContainer>
      </Flex>
      <Modal
        size='2xl'
        title='Vendor Account Create'
        isOpen={isVendorAccountCreateOpen}
        onClose={onVendorAccountCreateClose}
        isCentered={false}>
        <VendorAccountUpsert isCreateOnly={true} />
      </Modal>
      <Modal
        size='2xl'
        title='Vendor Account Update'
        isOpen={isVendorAccountUpdateOpen}
        onClose={onVendorAccountUpdateClose}
        isCentered={false}>
        <VendorAccountUpsert />
      </Modal>
      <Modal
        size='md'
        title='Add Sender ID'
        isOpen={isSenderIDAddOpen}
        onClose={onSenderIDAddClose}>
        <SenderID />
      </Modal>
      <Modal
        size='md'
        title='Vendor Account Delete'
        isOpen={isVendorAccountDeleteOpen}
        onClose={onVendorAccountDeleteClose}>
        <VendorAccountDelete />
      </Modal>
      <Modal
        size='2xl'
        title='Auto Rate Import'
        isOpen={isAutoRateImportOpen}
        onClose={onAutoRateImportClose}>
        <AutoRateImport />
      </Modal>
    </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryVendorAccountRead.data?.VendorAccountRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
