/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Browser validation library.
import _ from 'underscore' // Npm: Underscore library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { Button, Flex, Text, Checkbox, useBreakpointValue } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import PlatformMailerMutation from './__mutation__/index.platform.mailer.mutation'
import PlatformReadQuery from './__query__/index.platform.read.query'


/*
 * OBJECTS
 */
const Index = () => {
  // Hook assignment.
  const [forceReRender, setForceReRender] = React.useState(String.random(9))
  const [error, setError] = React.useState(void 0)
  const [isLoadingInProgress, setIsLoadingInProgress] = React.useState(false)
  const [MutationPlatformMailer, MutationPlatformMailerResponse] = useMutation(PlatformMailerMutation)
  const _PlatformReadQuery = useQuery(PlatformReadQuery, { 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async () => {
    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'generalSmtpConfiguration': JoiBrowser.object({
        'auth': JoiBrowser.object({
          'username': JoiBrowser.string().required(),
          'password': JoiBrowser.string().required(),
        }),
        'secure': JoiBrowser.boolean().required(),
        'host': JoiBrowser.string().required(),
        'port': JoiBrowser.number().required(),
        'senderEmail': JoiBrowser.string().required()
      }).required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Execute update mutation.
    const _MutationPlatformMailer = await MutationPlatformMailer({ 'variables': _formDataRef?.current })

    // If mutation caught an exception then report failure.
    if (_MutationPlatformMailer instanceof Error) return _MutationPlatformMailer

    // Style Guide.
    toast(_MutationPlatformMailer?.data?.PlatformMailer?.message)

    // Return void.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // Update formdata if data is available.
    if (_PlatformReadQuery?.data?.PlatformRead && _.first(_PlatformReadQuery?.data?.PlatformRead)?.status === 'READ_SUCCESSFUL') {
      // Const assignment.
      const _data = _.first(_PlatformReadQuery?.data?.PlatformRead)

      // Update form data.
      _formDataRef.current = {
        'generalSmtpConfiguration': {
          'auth': {
            'username': _data?.generalSmtpConfiguration?.auth?.username,
            'password': _data?.generalSmtpConfiguration?.auth?.password
          },
          'secure': Boolean(_data?.generalSmtpConfiguration?.secure),
          'certificateCheck': Boolean(_data?.generalSmtpConfiguration?.certificateCheck),
          'host': _data?.generalSmtpConfiguration?.host,
          'port': _data?.generalSmtpConfiguration?.port,
          'senderEmail': _data?.generalSmtpConfiguration?.senderEmail
        }
      }

      // Update state.
      setForceReRender(String.random(9))
    }
  }, [_PlatformReadQuery?.loading])

  // Return component.
  return (
    <Flex
      display='flex'
      flexDirection='column'
      gap={_isCurrentViewMobile ? '12px' : '22px'}
      bg='white'
      borderRadius='20px'
      p={_isCurrentViewMobile ? '12px' : '22px'}
      background='white'
      WebkitBackgroundClip='text'
      WebkitTextFillColor='transparent'
      flexDir='column'
      justify='space-between'
      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)' key={forceReRender}>
      <Text my='0' as='h2' fontSize='xl' color='gray.700'>Smtp Setting</Text>
      <Text my='0' w='100%' as='p' fontSize='17px' color='black' position='relative' top='-12px'>Update your general smtp configuration over here.</Text>
      <Flex w='100%' gap='16px' flexDir='column'>
        <Flex w='100%' flexDir='column' alignItems='center' gap='12px'>
          <Flex w='100%' gap='12px'>
            <MemoizedInput
              label='Username'
              padding='12px'
              placeholder='e.g. "XERDS#1234"'
              defaultValue={_formDataRef.current?.generalSmtpConfiguration?.auth?.username}
              disabled={_PlatformReadQuery?.loading || MutationPlatformMailerResponse?.loading}
              onChange={j => {
                // Update form data.
                _formDataRef.current['generalSmtpConfiguration'] = {
                  ..._formDataRef.current['generalSmtpConfiguration'],
                  'auth': {
                    ..._formDataRef.current['generalSmtpConfiguration']?.auth,
                    'username': j?.nativeEvent?.target?.value
                  }
                }
              }}
              error={error}
              isInvalid={error?.includes('username')}
              isRequired={true}
            />
            <MemoizedInput
              label='Password'
              padding='12px'
              placeholder='e.g. "XERDS****"'
              defaultValue={_formDataRef.current?.generalSmtpConfiguration?.auth?.password}
              disabled={_PlatformReadQuery?.loading || MutationPlatformMailerResponse?.loading}
              onChange={e => {
                // Update form data.
                _formDataRef.current['generalSmtpConfiguration'] = {
                  ..._formDataRef.current['generalSmtpConfiguration'],
                  'auth': {
                    ..._formDataRef.current['generalSmtpConfiguration']?.auth,
                    'password': e?.nativeEvent?.target?.value
                  }
                }
              }}
              error={error}
              isInvalid={error?.includes('password')}
              isRequired={true} />
          </Flex>
          <Flex mt='12px' w='100%' gap='12px'>
            <MemoizedInput
              label='Host'
              padding='12px'
              placeholder='e.g. "smtp.gmail.com"'
              defaultValue={_formDataRef.current?.generalSmtpConfiguration?.host}
              disabled={_PlatformReadQuery?.loading || MutationPlatformMailerResponse?.loading}
              onChange={j => {
                // Update form data.
                _formDataRef.current['generalSmtpConfiguration'] = {
                  ..._formDataRef.current['generalSmtpConfiguration'],
                  'host': j?.nativeEvent?.target?.value
                }
              }}
              error={error}
              isInvalid={error?.includes('host')}
              isRequired={true} />
            <MemoizedInput
              label='Port'
              padding='12px'
              placeholder='e.g "587"'
              defaultValue={_formDataRef.current?.generalSmtpConfiguration?.port}
              disabled={_PlatformReadQuery?.loading || MutationPlatformMailerResponse?.loading}
              onChange={e => {
                // Update form data.
                _formDataRef.current['generalSmtpConfiguration'] = {
                  ..._formDataRef.current['generalSmtpConfiguration'],
                  'port': parseInt(e?.nativeEvent?.target?.value, 10)
                }
              }}
              error={error}
              isInvalid={error?.includes('port')}
              isRequired={true} />
          </Flex>
          <Flex mt='12px' w='100%' alignItems='flex-end'>
            <MemoizedInput
              label='Sender Email'
              padding='12px'
              placeholder='e.g. "abc@xyc.sop"'
              defaultValue={_formDataRef.current?.generalSmtpConfiguration?.senderEmail ?? _.first(_PlatformReadQuery?.data?.PlatformRead)?.generalSmtpConfiguration?.senderEmail}
              disabled={_PlatformReadQuery?.loading || MutationPlatformMailerResponse?.loading}
              onChange={j => {
                // Update form data.
                _formDataRef.current['generalSmtpConfiguration'] = {
                  ..._formDataRef.current['generalSmtpConfiguration'],
                  'senderEmail': j?.nativeEvent?.target?.value
                }
              }}
              error={error}
              isInvalid={error?.includes('senderEmail')}
              isRequired={true} />
          </Flex>
          <Flex mt='12px' w='100%' gap='12px'>
            <Checkbox
              disabled={_PlatformReadQuery?.loading || MutationPlatformMailerResponse?.loading}
              colorScheme='brand'
              color='brand.500'
              textAlign='left'
              fontWeight={800}
              name='secure'
              isChecked={_formDataRef?.current?.generalSmtpConfiguration?.secure}
              onChange={({ target }) => {
                // Update form data.
                _formDataRef.current['generalSmtpConfiguration'] = {
                  ..._formDataRef.current['generalSmtpConfiguration'],
                  'secure': target?.checked
                }

                // Update state.
                setForceReRender(String.random(9))
              }}>
              Secure ?
            </Checkbox>
            <Checkbox
              disabled={_PlatformReadQuery?.loading || MutationPlatformMailerResponse?.loading}
              colorScheme='red'
              color='red.500'
              textAlign='left'
              fontWeight={800}
              name='certificateCheck'
              isChecked={_formDataRef?.current?.generalSmtpConfiguration?.certificateCheck}
              onChange={({ target }) => {
                // Update form data.
                _formDataRef.current['generalSmtpConfiguration'] = {
                  ..._formDataRef.current['generalSmtpConfiguration'],
                  'certificateCheck': target?.checked
                }

                // Update state.
                setForceReRender(String.random(9))
              }}>
              Certificate Check ?
            </Checkbox>
          </Flex>
        </Flex>
        <Flex w='100%' mt='12px' flexDir='column'>
              <Button
                px='12px'
                h='40px'
                w='100%'
                marginRight='0'
                marginLeft='auto'
                gap='6px'
                bg='brand.500'
                _hover={{ bg: 'brand.600' }}
                _active={{ bg: 'brand.600' }}
                _focus={{ bg: 'brand.600' }}
                color='white'
                onClick={async () => { setIsLoadingInProgress(true); await _SubmitForm(); setIsLoadingInProgress(false) }}
                disabled={_PlatformReadQuery?.loading || MutationPlatformMailerResponse?.loading}>
                {isLoadingInProgress ? 'Saving' : 'Save'}
              </Button>
            </Flex>
      </Flex>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORT
 */
export default Index



