/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation AutoRateImportUpdateMutation(
    $vendorAccountId: ID!
    $from: EmailAddress!,
    $bodySearchKeyword: String!, 
    $mccHeader: String!, 
    $mncHeader: String!, 
    $rateHeader: String!, 
    $rateDecimalSeparator: String!, 
    $startingRowColumn: String!
) {
  AutoRateImportUpdate(
    vendorAccountId: $vendorAccountId
    from: $from,
    bodySearchKeyword: $bodySearchKeyword, 
    mccHeader: $mccHeader, 
    mncHeader: $mncHeader, 
    rateHeader: $rateHeader, 
    rateDecimalSeparator: $rateDecimalSeparator, 
    startingRowColumn: $startingRowColumn
  ) {
    id,
    message,
    status
  }
}
`
