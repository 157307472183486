/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import Debounce from 'lodash/debounce' // Npm: Lodash debounce.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { HiMiniTrash } from 'react-icons/hi2' // Npm: Heroicons.
import {
  Checkbox,
  Flex,
  Text,
  Button,
  Spinner,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.



/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import AutoRateImportDeleteMutation from './__mutation__/index.autoRateImport.delete.mutation'
import AutoRateImportReadQuery from './__query__/index.autoRateImport.read.query'
import AutoRateImportUpdateMutation from './__mutation__/index.autoRateImport.update.mutation'
import AutoRateImportCreateMutation from './__mutation__/index.autoRateImport.create.mutation'


/*
 * OBJECTS
 */
const Index = ({ onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [MutationAutoRateImportCreate, MutationAutoRateImportCreateResponse] = useMutation(AutoRateImportCreateMutation)
  const [MutationAutoRateImportUpdate, MutationAutoRateImportUpdateResponse] = useMutation(AutoRateImportUpdateMutation)
  const [MutationAutoRateImportDelete, MutationAutoRateImportDeleteResponse] = useMutation(AutoRateImportDeleteMutation)
  const _QueryAutoRateImportRead = useQuery(AutoRateImportReadQuery, { 'variables': { 'vendorAccountId': passOn?.vendorAccountId }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _formDataRef = React.useRef({})
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Object assignment.
  const _isCreateOnly = _QueryAutoRateImportRead?.data?.AutoRateImportRead?.status === 'READ_SUCCESSFUL' ? false : true
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e?.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'from': JoiBrowser.string().required(),
      'bodySearchKeyword': JoiBrowser.string().required(),
      'mccHeader': JoiBrowser.string().required(),
      'mncHeader': JoiBrowser.string().required(),
      'rateHeader': JoiBrowser.string().required(),
      'rateDecimalSeparator': JoiBrowser.string().required(),
      'startingRowColumn': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = { ..._.pick(_formDataRef.current, _.identity), 'isSticky': _formDataRef.current?.isSticky }

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Create AutoRateImport for given Vendor.
    const _MutationAutoRateImport = await [_isCreateOnly ? MutationAutoRateImportCreate : MutationAutoRateImportUpdate]?.[0]({
      'variables': {
        'vendorAccountId': passOn?.vendorAccountId,
        ..._formDataRef.current
      }
    })

    // If creating or updating vendor account caught an exception then report failure.
    if (_MutationAutoRateImport instanceof Error) return _MutationAutoRateImport

    // Style Guide.
    toast(_isCreateOnly ? _MutationAutoRateImport?.data?.AutoRateImportCreate?.message : _MutationAutoRateImport?.data?.AutoRateImportUpdate?.message)

    // Only close if attach is successful.
    if ((_isCreateOnly ? 'CREATE_SUCCESSFUL' : 'UPDATE_SUCCESSFUL') === (_isCreateOnly ? _MutationAutoRateImport?.data?.AutoRateImportCreate?.status : _MutationAutoRateImport?.data?.AutoRateImportUpdate?.status)) return onClose?.()
    if (_MutationAutoRateImport?.data?.AutoRateImportCreate?.status?.includes('AUTO_RATE_IMPORT_ALREADY_EXISTS')) return onClose?.()

    // Return void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    /*
     * If details fetch complete then
     * update its value.
     */
    if (!_.isEmpty(_QueryAutoRateImportRead?.data?.AutoRateImportRead) && _QueryAutoRateImportRead?.data?.AutoRateImportRead?.status === 'READ_SUCCESSFUL') {
      // Update form data.
      _formDataRef.current = {
        'from': _QueryAutoRateImportRead?.data?.AutoRateImportRead?.from,
        'bodySearchKeyword': _QueryAutoRateImportRead?.data?.AutoRateImportRead?.bodySearchKeyword,
        'mccHeader': _QueryAutoRateImportRead?.data?.AutoRateImportRead?.mccHeader,
        'mncHeader': _QueryAutoRateImportRead?.data?.AutoRateImportRead?.mncHeader,
        'rateHeader': _QueryAutoRateImportRead?.data?.AutoRateImportRead?.rateHeader,
        'rateDecimalSeparator': _QueryAutoRateImportRead?.data?.AutoRateImportRead?.rateDecimalSeparator,
        'startingRowColumn': _QueryAutoRateImportRead?.data?.AutoRateImportRead?.startingRowColumn
      }

      // Update state.
      setForceReRender(String.random(9))
    }
  }, [_QueryAutoRateImportRead?.data?.AutoRateImportRead?.status])

  // Const assignment.
  const _isLoading = MutationAutoRateImportCreateResponse.loading || MutationAutoRateImportUpdateResponse.loading
  const _isInputDisabled = (_isCreateOnly ? false : _isLoading) || _QueryAutoRateImportRead.loading

  // Return component.
  return (
    <form onSubmit={_SubmitForm} key={forceReRender}>
      <Flex w='100%' gap='22px' flexDir='column'>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='from'
            label='From name'
            placeholder='e.g. "abc@xyz.abc"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isInvalid={error?.includes('from')}
            error={error}
            data={_formDataRef?.current?.from}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='bodySearchKeyword'
            label='Body Search Keyword'
            placeholder='e.g. "SPXEOD"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isRequired={true}
            error={error}
            isInvalid={error?.includes('bodySearchKeyword')}
            data={_formDataRef?.current?.bodySearchKeyword}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            name='mccHeader'
            label='Mcc Header Name'
            placeholder='e.g. "MCC"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isRequired={true}
            error={error}
            isInvalid={error?.includes('mccHeader')}
            data={_formDataRef?.current?.mccHeader}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='mncHeader'
            label='Mnc Header Name'
            placeholder='e.g. "MNC"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isRequired={true}
            error={error}
            isInvalid={error?.includes('mncHeader')}
            data={_formDataRef?.current?.mncHeader}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            name='rateHeader'
            label='Rate Header Name'
            placeholder='e.g. "RATE"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isRequired={true}
            error={error}
            isInvalid={error?.includes('rateHeader')}
            data={_formDataRef?.current?.rateHeader}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='rateDecimalSeparator'
            label='Rate Decimal Separator'
            placeholder='e.g. ","'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isRequired={true}
            error={error}
            isInvalid={error?.includes('rateDecimalSeparator')}
            data={_formDataRef?.current?.rateDecimalSeparator}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='startingRowColumn'
            label='Starting Column Row Number'
            placeholder='e.g. "3:1"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }

              // Report void.
              return void 0
            }}
            error={error}
            isInvalid={error?.includes('startingRowColumn')}
            data={_formDataRef?.current?.startingRowColumn}
          />
        </Flex>
      </Flex>
      <Flex
        w='100%'
        mt='22px'
        color='rgba(43, 54, 116, 1)'
        fontWeight='700'
        fontSize='20px'
        lineHeight='24px'
        fontFamily='DM Sans'
        alignItems='flex-end'
        justifyContent='space-between'
        flexDir={_isCurrentViewMobile ? 'column' : 'row'}
        gap='22px'>
        <Flex flexDir='column'>
          <Flex>
            <Checkbox
              disabled={_isInputDisabled}
              colorScheme='brand'
              color='brand.500'
              name='isSticky'
              isChecked={_formDataRef?.current?.isSticky}
              onChange={({ target }) => {
                // Over spreading.
                const { name, checked } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: checked
                }

                // Update state.
                setForceReRender(String.random(9))
              }}>
              Secure Connection ?
            </Checkbox>
          </Flex>
          <Text fontSize='14px' fontWeight='500' color='gray.500'>Only Makes Connection in <span style={{ 'fontWeight': 900 }}>SSL</span> mode.</Text>
        </Flex>
        <Flex flexDir='column' gap='12px'>
          <SubmitButton
            mt={0}
            w='100%'
            disabled={_isInputDisabled}
            onSubmit={_SubmitForm}
            defaultText='Upsert Configuration'
            isLoading={_isLoading} />
          <Button bg='red.500' _hover={{ 'bg': 'red.600' }} _active={{ 'bg': 'red.600' }} _focus={{ 'bg': 'red.600' }} color='white' gap='12px' borderRadius={12} disabled={_isCreateOnly || _isInputDisabled} onClick={() => MutationAutoRateImportDelete({ 'variables': { 'vendorAccountId': passOn?.vendorAccountId } })?.then((r) => { toast(r?.data?.AutoRateImportDelete?.message); onClose?.() })}>
            Delete Configuration
            {MutationAutoRateImportDeleteResponse?.loading ? <Spinner size='sm' /> : <HiMiniTrash size={17} />}
          </Button>
        </Flex>
      </Flex>
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'onClose': PropTypes.func,
  'passOn': PropTypes.object,
  'isOpen': PropTypes.bool
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
