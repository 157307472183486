/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import _ from 'underscore' // Npm: underscore library.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { HiDocumentText } from 'react-icons/hi2' // Npm: React icon library.
import {
  Button,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import DateRangePicker from 'components/DateRangePicker'
import EntitySelector from 'components/EntitySelector'
import CustomerSelector from 'components/CustomerSelector'


/*
 * GRAPHS
 */
import BilateralReadQuery from './__query__/index.bilateral.read.query'
import BilateralStatementGenerate from './__mutation__/index.bilateral.statementGenerate.mutation'


/*
 * STYLES
 */
import { cellStyle, headerStyle, lastRowStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Hook assignment.
  const [customerId, setCustomerId] = React.useState('')
  const [entityId, setEntityId] = React.useState('')
  const [customerInvoices, setCustomerInvoices] = React.useState([])
  const [isGeneratingSOAInProgress, setIsGeneratingSOAInProgress] = React.useState(false)
  const [customerPayment, setCustomerPayment] = React.useState([])
  const [vendorPayment, setVendorPayment] = React.useState([])
  const [vendorInvoices, setVendorInvoices] = React.useState([])
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const [MutationBilateralStatementGenerate] = useMutation(BilateralStatementGenerate)
  const [MutationBilateralRead, MutationBilateralReadResponse] = useLazyQuery(BilateralReadQuery, { 'variables': { customerId, entityId, 'startDate': dateRange?.selection?.startDate, 'endDate': dateRange?.selection?.endDate }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _tableHeaderHeightRef = React.useRef(0)
  const _bilateralInformationRef = React.useRef({})
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Object assignment.
  const _GenerateSOA = async () => {
    // Update state.
    setIsGeneratingSOAInProgress(true)

    // Generate SOA.
    const _MutationBilateralStatementGenerate = await MutationBilateralStatementGenerate({ 'variables': { customerId, entityId, 'startDate': dateRange?.selection?.startDate, 'endDate': dateRange?.selection?.endDate } })

    // Update state.
    setIsGeneratingSOAInProgress(false)

    // If mutation caught an exception then report failure.
    if (_MutationBilateralStatementGenerate instanceof Error) return _MutationBilateralStatementGenerate

    // Const assignment.
    const _statement = _MutationBilateralStatementGenerate?.data?.BilateralStatementGenerate?.statement?.path

    // Download link if statement is generated.
    if (_.isEmpty(_statement)) return toast('Failed to generate the statement.')

    // Download statement.
    const a = document.createElement('a')

    // Create a blob url.
    a.href = _statement

    // Set download attribute.
    a.download = _statement.split('/').pop()

    // Append to body.
    document.body.appendChild(a)

    // Click the element.
    a.click()

    // Remove element.
    document.body.removeChild(a)

    // Return response.
    return toast('Statement generated successfully.')
  }

  // Event handler.
  React.useEffect(() => {
    // Async handler.
    const _Async = async () => {
      // Call bilateral read if all parameters are set.
      if (customerId && entityId && dateRange?.selection?.startDate && dateRange?.selection?.endDate) {
        // Const assignment.
        const _MutationBilateralRead = await MutationBilateralRead({ 'variables': { customerId, entityId, 'startDate': dateRange?.selection?.startDate, 'endDate': dateRange?.selection?.endDate } })

        // If mutation caught an exception then report failure.
        if (_MutationBilateralRead instanceof Error) return _MutationBilateralRead

        /*
         * If response is not empty then loop and update
         * all states.
         */
        if (!_.isEmpty(_MutationBilateralRead?.data) && _.isArray(_MutationBilateralRead?.data?.BilateralRead)) {
          // Loop through br and update states.
          const _customerInvoices = _.compact(_.flatten(_.pluck(_.compact(_.flatten(_.pluck(_MutationBilateralRead?.data?.BilateralRead, 'Customer'))), 'CustomerInvoice')))
          const _customerPayment = _.compact(_.flatten(_.pluck(_.compact(_.flatten(_.pluck(_MutationBilateralRead?.data?.BilateralRead, 'Customer'))), 'Payment')))
          const _vendorInvoices = _.compact(_.flatten(_.pluck(_.compact(_.flatten(_.pluck(_MutationBilateralRead?.data?.BilateralRead, 'Vendor'))), 'VendorInvoice')))
          const _vendorPayment = _.compact(_.flatten(_.pluck(_.compact(_.flatten(_.pluck(_MutationBilateralRead?.data?.BilateralRead, 'Vendor'))), 'Payment')))

          // Update all payments and invoices with total.
          const _customerPaymentTotal = _customerPayment.reduce((a, b) => a + b.amount, 0)
          const _customerInvoicesTotal = _customerInvoices.reduce((a, b) => a + b.amount, 0)
          const _vendorPaymentTotal = _vendorPayment.reduce((a, b) => a + b.amount, 0)
          const _vendorInvoicesTotal = _vendorInvoices.reduce((a, b) => a + b.amount, 0)

          // Set bilateral information.
          _bilateralInformationRef.current = {
            'customerInvoices': _customerInvoices,
            'customerPayment': _customerPayment,
            'vendorInvoices': _vendorInvoices,
            'vendorPayment': _vendorPayment,
            'customerInvoicesTotal': _customerInvoicesTotal?.toFixed(2),
            'customerPaymentTotal': _customerPaymentTotal?.toFixed(2),
            'vendorInvoicesTotal': _vendorInvoicesTotal?.toFixed(2),
            'vendorPaymentTotal': _vendorPaymentTotal?.toFixed(2)
          }

          // Set invoices.
          setCustomerInvoices(_.compact([..._customerInvoices, 0 <= _customerInvoicesTotal ? { 'amount': _customerInvoicesTotal } : void 0]))
          setCustomerPayment(_.compact([..._customerPayment, 0 <= _customerPaymentTotal ? { 'amount': _customerPaymentTotal } : void 0]))
          setVendorInvoices(_.compact([..._vendorInvoices, 0 <= _vendorInvoicesTotal ? { 'amount': _vendorInvoicesTotal } : void 0]))
          setVendorPayment(_.compact([..._vendorPayment, 0 <= _vendorPaymentTotal ? { 'amount': _vendorPaymentTotal } : void 0]))

          // Report void 0.
          return void 0
        }
      }

      // Return void.
      return void 0
    }; _Async()
  }, [customerId, entityId, dateRange])

  // Return component.
  return (
    <Flex flexDir='column' w='100%' overflowY='auto' borderRadius='22px'>
      <Flex mb='22px' alignItems='center' justifyContent='space-between' p='12px' pl={_isCurrentViewMobile ? '12px' : '22px'} borderRadius={18} color='white' bg='linear-Gradient(73deg,#390EF6 0%, #9244F4 100%)'>
        <Flex flexDir='row' flexWrap='wrap' textAlign='left' gap='6px'>
          <Text>Customer Invoices: <Text as='span' fontWeight={600}>{(_bilateralInformationRef.current.customerInvoicesTotal ?? 0)}</Text>,</Text>
          <Text>Customer Payments: <Text as='span' fontWeight={600}>{(_bilateralInformationRef.current.customerPaymentTotal ?? 0)}</Text>,</Text>
          <Text>Vendor Invoices: <Text as='span' fontWeight={600}>{(_bilateralInformationRef.current.vendorInvoicesTotal ?? 0)}</Text>,</Text>
          <Text>Vendor Payments: <Text as='span' fontWeight={600}>{(_bilateralInformationRef.current.vendorPaymentTotal ?? 0)}</Text>,</Text>
          <Text>Total: <Text as='span' fontWeight={600}>{((((_bilateralInformationRef.current.customerInvoicesTotal ?? 0) - (_bilateralInformationRef.current.customerPaymentTotal ?? 0)) - ((_bilateralInformationRef.current.vendorInvoicesTotal ?? 0) - (_bilateralInformationRef.current.vendorPaymentTotal ?? 0))) * -1)?.toFixed?.(5)}</Text></Text>
        </Flex>
        <Button type='basic' ml='12px' p={_isCurrentViewMobile ? '0' : void 0} color='white' bg='purple.800' _hover={{ 'bg': 'purple.800' }} _focus={{ 'bg': 'purple.800' }} gap='6px' onClick={_GenerateSOA} disabled={_.isEmpty(customerId) || _.isEmpty(entityId)}>{_isCurrentViewMobile ? void 0 : 'Generate SOA'} {isGeneratingSOAInProgress ? <Spinner size='sm' /> : <HiDocumentText size={17} />}</Button>
      </Flex>
      <Flex flexDirection={{ 'base': 'column', 'md': 'row' }} gap='12px' overflowY='auto' borderRadius='22px'>
        <Flex
          display='flex'
          w={{ 'base': '100%', 'md': '50%' }}
          flexDirection='column'
          gap='22px'
          bg='white'
          h='max-content'
          borderRadius='20px'
          p='22px'>
          <Flex pb='0px' alignItems='end' justifyContent='space-between' >
            <Flex w='100%' flexDir={_isCurrentViewMobile ? 'column' : 'row'} justifyContent='space-between' gap='12px' alignItems='center'>
              <Flex flex={_isCurrentViewMobile ? 1 : 0.7} flexDir='column' gap='22px'>
                <Flex flexDir='column' >
                  <Text fontSize='2xl' fontWeight={300} mb={2}>Customer Statements</Text>
                  <Text w={{ 'base': '100%', 'md': '80%' }} fontSize='lg' color='gray.500'>All invoices issued for selected customer will going to be displayed.</Text>
                  <Flex py='10px' gap='12px' alignItems='flex-start' flexDir='column' justifyContent='space-between' mt='12px'>
                    <Text color='red.500' fontWeight={600}>{_isCurrentViewMobile ? 'Date Range' : 'Select Date Range'}</Text>
                    <DateRangePicker startDate={dateRange?.selection?.startDate} endDate={dateRange?.selection?.endDate} w='100%' fontSize='13px' height='max-content' gap='6px' setSelectDate={setDateRange} />
                  </Flex>
                </Flex>
              </Flex>
              <Flex flex={0.3} flexDir='column' gap='12px' justifyContent='flex-end' zIndex={100}>
                <Flex mt='0' width='100%' gap='12px'>
                  <Flex mt='0' width='100%' flex={1}>
                    <CustomerSelector
                      placeholder='Select Customer'
                      color='white'
                      height='100%'
                      bg='brand.500'
                      _focus={{ 'borderWidth': 0 }}
                      onChange={j => setCustomerId(j.target.value?.split?.('(')?.[1]?.split?.(')')?.[0])}
                    />
                  </Flex>
                </Flex>
                <Flex mt='0' width='100%' gap='12px'>
                  <Flex mt='0' width='100%' flex={1}>
                    <EntitySelector
                      placeholder='Select Entity'
                      color='white'
                      height='100%'
                      bg='brand.500'
                      onChange={j => setEntityId(j.target.value?.split?.('(')?.[1]?.split?.(')')?.[0])}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Text mt='22px' color='brand.500' fontWeight='600'>Customer Invoice&apos;s</Text>
          <TableContainer
            flex={1}
            h='50%'
            minH='350px'
            maxH='720px'
            display='flex'
            overflowY='scroll'
            borderRadius='15px'
            outline='1px solid #390EF6'>
            <Table variant='simple' size='sm'>
              <Thead
                ref={_tableHeaderHeightRef}
                color='rgba(141, 151, 182, 1)'
                fontStyle='DM Sans'
                fontWeight='500'
                fontSize='18px'
                lineHeight='24px'>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': '0', 'zIndex': '1' }}>
                  <Td
                    py='10px'
                    textAlign='center'
                    px='0px'
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Text>Invoice Id</Text>
                  </Td>
                  <Td
                    py='10px'
                    textAlign='center'
                    px='0px'
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Text>Billing Start Date</Text>
                  </Td>
                  <Td
                    py='10px'
                    textAlign='center'
                    px='0px'
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Text>Billing End Date</Text>
                  </Td>
                  <Td
                    style={rowStyle}
                    textAlign='center'>
                    <Text>Amount</Text>
                  </Td>
                </Tr>
              </Thead>
              <Tbody style={cellStyle}>
                {MutationBilateralReadResponse.loading ? (
                  <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
                ) : 0 === customerInvoices?.length ? (
                  <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
                ) : (
                  <>
                    {customerInvoices?.map(({ id, billingStartDate, billingEndDate, amount }, __index) => (
                      <Tr style={__index === customerInvoices?.length - 1 ? lastRowStyle : void 0} bg={__index === customerInvoices?.length - 1 ? 'linear-Gradient(73deg, #390EF6 0%, #390EF6 64%, rgba(146, 68, 244, 1) 130%)' : void 0} color={__index === customerInvoices?.length - 1 ? 'white' : void 0} key={String.random(9)}>
                        <Td
                          style={rowStyle}
                          borderRight={__index === customerInvoices?.length - 1 ? void 0 : '1px solid rgba(216, 227, 252, 1)'}
                          borderBottomWidth={__index === customerInvoices?.length - 1 ? 0 : void 0}>
                          {' '}
                          {id}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight={__index === customerInvoices?.length - 1 ? void 0 : '1px solid rgba(216, 227, 252, 1)'}
                          borderBottomWidth={__index === customerInvoices?.length - 1 ? 0 : void 0}>
                          {__index === customerInvoices?.length - 1 ? 'TOTAL' : Moment(billingStartDate).format('YYYY-MM-DD')}{' '}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight={__index === customerInvoices?.length - 1 ? void 0 : '1px solid rgba(216, 227, 252, 1)'}
                          borderBottomWidth={__index === customerInvoices?.length - 1 ? 0 : void 0}>
                          {__index === customerInvoices?.length - 1 ? 'TOTAL' : Moment(billingEndDate).format('YYYY-MM-DD')}{' '}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderBottomWidth={__index === customerInvoices?.length - 1 ? 0 : void 0}
                          py='22px'>
                          {amount?.toFixed(2)}{' '}
                        </Td>
                      </Tr>
                    ))}
                  </>
                )
                }
              </Tbody>
            </Table>
          </TableContainer>
          <Text mt='22px' color='brand.500' fontWeight='600'>Customer Payment&rsquo;s</Text>
          <TableContainer
            flex={1}
            h='50%'
            minH='350px'
            maxH='720px'
            display='flex'
            overflowY='scroll'
            borderRadius='15px'
            outline='1px solid #390EF6'>
            <Table variant='simple' size='sm'>
              <Thead
                ref={_tableHeaderHeightRef}
                color='rgba(141, 151, 182, 1)'
                fontStyle='DM Sans'
                fontWeight='500'
                fontSize='18px'
                lineHeight='24px'>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': '0', 'zIndex': '1' }}>
                  <Td
                    py='10px'
                    textAlign='center'
                    px='0px'
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Text>Payment Id</Text>
                  </Td>
                  <Td
                    py='10px'
                    textAlign='center'
                    px='0px'
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Text>CreatedAt</Text>
                  </Td>
                  <Td
                    style={rowStyle}
                    textAlign='center'>
                    <Text>Amount</Text>
                  </Td>
                </Tr>
              </Thead>
              <Tbody style={cellStyle}>
                {MutationBilateralReadResponse.loading ? (
                  <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
                ) : 0 === customerPayment?.length ? (
                  <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
                ) : (
                  <>
                    {customerPayment?.map(({ id, createdAt, amount }, __index) => (
                      <Tr style={__index === customerPayment?.length - 1 ? lastRowStyle : void 0} bg={__index === customerPayment?.length - 1 ? 'linear-Gradient(73deg, #390EF6 0%, #390EF6 64%, rgba(146, 68, 244, 1) 130%)' : void 0} color={__index === customerPayment?.length - 1 ? 'white' : void 0} key={String.random(9)}>
                        <Td
                          style={rowStyle}
                          borderRight={__index === customerPayment?.length - 1 ? void 0 : '1px solid rgba(216, 227, 252, 1)'}
                          borderBottomWidth={__index === customerPayment?.length - 1 ? 0 : void 0}>
                          {' '}
                          {id}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight={__index === customerPayment?.length - 1 ? void 0 : '1px solid rgba(216, 227, 252, 1)'}
                          borderBottomWidth={__index === customerPayment?.length - 1 ? 0 : void 0}>
                          {__index === customerPayment?.length - 1 ? 'TOTAL' : Moment(createdAt).format('YYYY-MM-DD')}{' '}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderBottomWidth={__index === customerPayment?.length - 1 ? 0 : void 0}
                          py='22px'>
                          {amount?.toFixed(2)}{' '}
                        </Td>
                      </Tr>
                    ))}
                  </>
                )
                }
              </Tbody>
            </Table>
          </TableContainer>
        </Flex>
        <Flex
          display='flex'
          h='max-content'
          w={{ 'base': '100%', 'md': '50%' }}
          flexDirection='column'
          gap='22px'
          bg='white'
          borderRadius='20px'
          p='22px'>
          <Flex pb='0px' alignItems='end' justifyContent='space-between'>
            <Flex w='100%' flexDir='row' justifyContent='space-between' gap='12px' alignItems='center'>
              <Flex flex={_isCurrentViewMobile ? 1 : 0.7} flexDir='column' gap='22px'>
                <Flex flexDir='column'>
                  <Text fontSize='2xl' fontWeight={300} mb={2}>Vendor Statements</Text>
                  <Text w={{ 'base': '100%', 'md': '80%' }} fontSize='lg' color='gray.500'>All Vendor settlement done with customer having bilateral relation.</Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Text mt='22px' color='purple.600' fontWeight='600'>Vendor Invoice&rsquo;s</Text>
          <TableContainer
            minH='350px'
            maxH='720px'
            display='flex'
            overflowY='scroll'
            borderRadius='15px'
            outline='1px solid #8166CA'>
            <Table variant='simple' size='sm'>
              <Thead
                ref={_tableHeaderHeightRef}
                color='rgba(141, 151, 182, 1)'
                fontStyle='DM Sans'
                fontWeight='500'
                fontSize='18px'
                lineHeight='24px'>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': '0', 'zIndex': '1' }}>
                  <Td
                    py='10px'
                    textAlign='center'
                    px='0px'
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Text>Invoice Id</Text>
                  </Td>
                  <Td
                    py='10px'
                    textAlign='center'
                    px='0px'
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Text>Dated On</Text>
                  </Td>
                  <Td
                    style={rowStyle}
                    textAlign='center'>
                    <Text>Amount</Text>
                  </Td>
                </Tr>
              </Thead>
              <Tbody style={cellStyle}>
                {MutationBilateralReadResponse.loading ? (
                  <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
                ) : 0 === vendorInvoices?.length ? (
                  <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
                ) : (
                  <>
                    {vendorInvoices?.map(({ invoiceNumber, datedOn, amount }, __index) => (
                      <Tr style={__index === vendorInvoices?.length - 1 ? lastRowStyle : void 0} bg={__index === vendorInvoices?.length - 1 ? 'linear-Gradient(73deg, #6629F5 44%, rgba(146, 68, 244, 1) 130%)' : void 0} color={__index === vendorInvoices?.length - 1 ? 'white' : void 0} key={String.random(9)}>
                        <Td
                          style={rowStyle}
                          borderRight={__index === vendorInvoices?.length - 1 ? void 0 : '1px solid rgba(216, 227, 252, 1)'}
                          borderBottomWidth={__index === vendorInvoices?.length - 1 ? 0 : void 0}>
                          {invoiceNumber}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight={__index === vendorInvoices?.length - 1 ? void 0 : '1px solid rgba(216, 227, 252, 1)'}
                          borderBottomWidth={__index === vendorInvoices?.length - 1 ? 0 : void 0}>
                          {__index === vendorInvoices?.length - 1 ? 'TOTAL' : Moment(datedOn).format('YYYY-MM-DD')}{' '}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderBottomWidth={__index === vendorInvoices?.length - 1 ? 0 : void 0}
                          py='22px'>
                          {amount?.toFixed(2)}{' '}
                        </Td>
                      </Tr>
                    ))}
                  </>
                )
                }
              </Tbody>
            </Table>
          </TableContainer>
          <Text mt='22px' color='purple.600' fontWeight='600'>Vendor Payment&apos;s</Text>
          <TableContainer
            flex={1}
            minH='350px'
            maxH='720px'
            display='flex'
            overflowY='scroll'
            borderRadius='15px'
            outline='1px solid #8166CA'>
            <Table variant='simple' size='sm'>
              <Thead
                ref={_tableHeaderHeightRef}
                color='rgba(141, 151, 182, 1)'
                fontStyle='DM Sans'
                fontWeight='500'
                fontSize='18px'
                lineHeight='24px'>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': '0', 'zIndex': '1' }}>
                  <Td
                    py='10px'
                    textAlign='center'
                    px='0px'
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Text>Payment Id</Text>
                  </Td>
                  <Td
                    py='10px'
                    textAlign='center'
                    px='0px'
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Text>CreatedAt</Text>
                  </Td>
                  <Td
                    style={rowStyle}
                    textAlign='center'>
                    <Text>Amount</Text>
                  </Td>
                </Tr>
              </Thead>
              <Tbody style={cellStyle}>
                {MutationBilateralReadResponse.loading ? (
                  <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
                ) : 0 === vendorPayment?.length ? (
                  <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
                ) : (
                  <>
                    {vendorPayment?.map(({ id, createdAt, amount }, __index) => (
                      <Tr style={__index === vendorPayment?.length - 1 ? lastRowStyle : void 0} bg={__index === vendorPayment?.length - 1 ? 'linear-Gradient(73deg, #6629F5 44%, rgba(146, 68, 244, 1) 130%)' : void 0} color={__index === vendorPayment?.length - 1 ? 'white' : void 0} key={String.random(9)}>
                        <Td
                          style={rowStyle}
                          borderRight={__index === vendorPayment?.length - 1 ? void 0 : '1px solid rgba(216, 227, 252, 1)'}
                          borderBottomWidth={__index === vendorPayment?.length - 1 ? 0 : void 0}>
                          {' '}
                          {id}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight={__index === vendorPayment?.length - 1 ? void 0 : '1px solid rgba(216, 227, 252, 1)'}
                          borderBottomWidth={__index === vendorPayment?.length - 1 ? 0 : void 0}>
                          {__index === vendorPayment?.length - 1 ? 'TOTAL' : Moment(createdAt).format('YYYY-MM-DD')}{' '}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderBottomWidth={__index === vendorPayment?.length - 1 ? 0 : void 0}
                          py='22px'>
                          {amount?.toFixed(2)}{' '}
                        </Td>
                      </Tr>
                    ))}
                  </>
                )
                }
              </Tbody>
            </Table>
          </TableContainer>
        </Flex>
      </Flex>
    </Flex>
  )
}


/*
 * EXPORTS
 */
export default Index
